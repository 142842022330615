import React from 'react';
import { Image, ImageFit } from 'office-ui-fabric-react/lib-commonjs/Image';
import './GridItem.css';

const styles = {
  itemContainer: {
    margin: '10px',
    width: '300px',
  },
  currentPrice: {
    display: 'inline',
    margin: '0px 5px',
  },
  fullPrice: {
    display: 'inline',
    margin: '0px 5px',
    textDecoration: 'line-through',
  },
};

export default function GridItem(props) {
  const { item } = props;
  const price = item.discounted ? (
    <div>
      <p className="ncss-base text-color-dark-grey fs14-sm" style={styles.currentPrice}>{`$${item.currentPrice}`}</p>
      <p className="ncss-base text-color-secondary" style={styles.fullPrice}>{`$${item.fullPrice}`}</p>
    </div>
  ) : (<p className="ncss-base text-color-dark-grey fs14-sm">{`$${item.fullPrice}`}</p>);
  return (
    <div style={styles.itemContainer} key={item.styleColor} className="ta-sm-c">
      <a className="linkHoverOverride" href={item.pdpUrl} target="_blank noopener noreferrer">
        <Image
          className="mauto-sm"
          src={`https://images.nike.com/is/image/DotCom/PDP_HERO/${item.label.replace('-', '_')}_A_PREM/nike_vision_${item.label}.png?wid=250&hei=250&fmt=png-alpha`}
          width={150}
          height={150}
          imageFit={ImageFit.contain}
        />
        <p className="ncss-base text-color-dark-grey fs14-sm">{`${item.title}`}</p>
        <p className="ncss-base text-color-dark-grey fs14-sm">{`${item.label}`}</p>
        <p className="ncss-base text-color-dark-grey fs14-sm">{`Rank: ${item.rank}`}</p>
        { price }
      </a>
    </div>
  );
}
