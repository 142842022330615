import { withAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import AppBar from '@nike/nr-vamp-xl-app-bar';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { checkAuthentication } from '../../utils/helpers';
import AppControls from '../../components/AppControls/AppControls';
import PreviewCards from '../../components/PreviewCards/PreviewCards';
import ListGrid from '../../components/ListGrid/ListGrid';
import providersConfig from '../../providers.config';
import merchGroupsConfig from '../../merchGroups.config';
import config from '../../config';

const { clientId } = config.oidc;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      userinfo: null,
      loading: null,
      file: [],
      providers: providersConfig,
      merchGroups: merchGroupsConfig,
      resultsRenderCount: 50,
      countryCode: { value: 'US', label: 'United States' },
      productDetailsHash: {},
    };
    this.checkAuthentication = checkAuthentication.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.buildRequestUrls = this.buildRequestUrls.bind(this);
    this.buildMerchRequestUrl = this.buildMerchRequestUrl.bind(this);
    this.buildMerchGroupHash = this.buildMerchGroupHash.bind(this);
    this.buildClickthroughUri = this.buildClickthroughUri.bind(this);
    this.addRank = this.addRank.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.makeRequests = this.makeRequests.bind(this);
  }

  async componentDidMount() {
    this.checkAuthentication();
  }

  async componentDidUpdate() {
    this.checkAuthentication();
  }

  setRequestParameters({ providerIndex, key, value }) {
    const providers = [...this.state.providers];
    if (['providersToUse', 'providersToAggregate', 'aggregatorsToUse'].includes(key)) {
      if (providers[providerIndex][key].includes(value)) {
        providers[providerIndex][key] = providers[providerIndex][key]
          .filter(item => item !== value);
      } else {
        providers[providerIndex][key].push(value);
      }
    } else {
      providers[providerIndex][key] = value;
    }
    this.setState({ providers });
  }

  async makeRequests() {
    let { providers } = this.state;
    const { countryCode } = this.state;
    const { value } = countryCode;
    const { file } = this.state;
    const { base64 } = file;

    providers.forEach((provider, i) => {
      this.setRequestParameters({ providerIndex: i, key: 'results', value: [] });
      this.setRequestParameters({ providerIndex: i, key: 'error', value: '' });
      this.setRequestParameters({ providerIndex: i, key: 'showSpinner', value: true });
    });
    this.setState(async () => {
      providers = this.buildRequestUrls(providers);
      providers.forEach(async (provider, i) => {
        let response;
        const config = {
          ...provider,
          imageBase64: base64,
          countryCode: value || 'US',
          gender: provider.gender,
          returnCrop: true,
        };
        const headers = {
          Authorization: 'NIKECAM_BYPASS_FOR_INTERNAL',
          'X-API-KEY': clientId,
        };
        try {
          response = await axios.post(provider.requestUrl, config, { headers });
          const merchGroupRequestUrl = this.buildMerchRequestUrl(response);
          const merchGroupsResp = await axios.get(merchGroupRequestUrl);
          const merchGroupHash = this.buildMerchGroupHash(merchGroupsResp);
          this.handleResponse(i, response, merchGroupHash);
          return response;
        } catch (e) {
          this.handleError({ providerIndex: i, error: e });
        }
      });
    });
  }

  handleResponse(i, response, merchGroupHash) {
    if (response && response.data.results) {
      this.setState((prevState) => {
        // set provider results
        const providers = [...prevState.providers];
        providers[i].results = this.addRank(response.data.results.recommendations, merchGroupHash);
        providers[i].showSpinner = false;
        return { providers };
      });
    } else {
      this.handleError({ providerIndex: i, response });
    }
  }

  handleFileInput(e) {
    // to be able to handle both on click and drag and drop
    const file = e.target ? e.target.files[0] : e[0];
    if (!file) return;
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result.replace(
        /^data:image\/\w+;base64,/,
        '',
      );
      const label = file.name.match(/[a-zA-Z0-9]{6,7}[-_]{1}[0-9]{3}/);
      let pdpUrl;
      if (label) {
        pdpUrl = `https://images.nike.com/is/image/DotCom/PDP_HERO/${label[0].replace(
          '-',
          '_',
        )}_A_PREM/nike_vision.png?wid=299&hei=299&fmt=png-alpha`;
      }
      const fileInfo = {
        name: file.name,
        label: label ? label[0] : '',
        pdpUrl,
        type: file.type,
        size: `${Math.round(file.size / 1000)} kB`,
        base64,
        file,
      };
      this.setState({ file: fileInfo });
      this.makeRequests();
    };
  }

  handleError({ providerIndex, response, error }) {
    const genericError = 'An unexpected error occurred, please wait a few seconds and try again. If this issues persists, please contact your manager and have them reach out to the Nike Cam team regarding this error.';
    const statusMessages = {
      INTERNAL_SERVER_ERROR: genericError,
      BAD_REQUEST: genericError,
      PROVIDER_ERROR: genericError,
      UNACCEPTABLE_IMAGE_URL: genericError,
      NO_RECOMMENDATIONS: 'Unfortunately no matches were found for this product. Please try submitting another image.',
      UNACCEPTABLE_FILE_TYPE: 'We are unable to process this file type, please try submitting another image.',
      UNSUPPORTED_COUNTRY_CODE: 'There was an error with the country code, please contact your manager and have them reach out to the Nike Cam team regarding this error.',
      UNSUPPORTED_MERCH_GROUP: 'There was an error with the merch group, please contact your manager and have them reach out to the Nike Cam team regarding this error.',
      NO_SHOES_DETECTED: 'No shoe was detected in the image. Please submit another image and try again.',
    };
    this.setState((prevState) => {
      const providers = [...prevState.providers];
      if (response && response.data.error) {
        providers[providerIndex].error = response.data.error;
      } else if (error) {
        const errorMessage = error.response
          ? statusMessages[error.response.data.error]
          : error.message;
        providers[providerIndex].error = errorMessage;
      }
      providers[providerIndex].showSpinner = false;
      return { providers };
    });
  }

  buildClickthroughUri(slug, styleColor) {
    return `https://www.nike.com/t/${slug}/${styleColor}`;
  }

  buildMerchGroupHash(merchGroupResponse) {
    return merchGroupResponse.data.objects.map((product) => {
      const { title, slug } = product.productInfo[0].productContent;
      const { styleColor } = product.productInfo[0].merchProduct;
      const { discounted, fullPrice, currentPrice } = product.productInfo[0].merchPrice;
      const pdpUrl = this.buildClickthroughUri(slug, styleColor);
      return {
        title,
        pdpUrl,
        styleColor,
        discounted,
        fullPrice,
        currentPrice,
      };
    }).reduce((acc, product) => {
      const {
        title,
        pdpUrl,
        styleColor,
        discounted,
        fullPrice,
        currentPrice,
      } = product;
      if (!acc[styleColor]) {
        acc[styleColor] = {
          title,
          pdpUrl,
          styleColor,
          discounted,
          fullPrice,
          currentPrice,
        };
      }
      return acc;
    }, {});
  }

  buildMerchRequestUrl(response) {
    const baseUrl = 'https://api.nike.com//product_feed/threads/v2?';
    const styleColorsList = response.data.results.recommendations.map(product => (product.label));
    const styleColors = `&filter=publishedContent.properties.products.styleColor(${styleColorsList.join(',')})`;
    const consumerChannel = '&consumerChannelId=82a74ac1-c527-4470-b7b0-fb5f3ef3c2e2&view=VISUAL_SEARCH_FOOTWEAR';
    const requestUrl = `${baseUrl}filter=channelId(d9a5bc42-4b9c-4976-858a-f159cf99c647)&filter=language(en)&filter=marketplace(US)${styleColors}${consumerChannel}`;
    return requestUrl;
  }

  buildRequestUrls(providers) {
    const { requestUrl } = config.internalApi;
    return providers.map(provider => ({
      ...provider,
      requestUrl,
    }));
  }

  async login() {
    const { auth } = this.props;
    auth.login('/');
  }

  async logout() {
    const { auth } = this.props;
    auth.logout('/');
  }

  addRank(recommendations, merchGroupsResp) {
    return recommendations.map((product) => {
      const { label } = product;
      if (merchGroupsResp && merchGroupsResp[label]) {
        const {
          title,
          pdpUrl,
          discounted,
          fullPrice,
          currentPrice,
        } = merchGroupsResp[label];
        return {
          ...product, title, pdpUrl, discounted, fullPrice, currentPrice,
        };
      }
      return null;
    }).filter(product => product);
  }

  render() {
    const { authenticated } = this.state;
    const { file } = this.state;
    const { providers } = this.state;
    const { resultsRenderCount } = this.state;

    return (
      <div>
        <AppBar label="" withDefault={false}>
          {
            !authenticated
            && (
              <Button
                className="ncss-btn-primary-light fl-xxl-r"
                onClick={this.login}
              >
                Login
              </Button>
            )
          }
          {
            authenticated === true
            && (
              <Button
                className="ncss-btn-primary-light fl-xxl-r"
                onClick={this.logout}
              >
                Logout
              </Button>
            )
          }
        </AppBar>
        {
          authenticated === true
          && (
            <div className="ncss-container mt4-xxl mt2-sm">
              <AppControls
                handleFileInput={this.handleFileInput}
                makeRequests={this.makeRequests}
              />
              <div className="ncss-row">
                <PreviewCards
                  handleFileInput={this.handleFileInput}
                  makeRequests={this.makeRequests}
                  file={file}
                />
                <ListGrid
                  providers={providers}
                  renderCount={resultsRenderCount}
                />
              </div>
            </div>
          )
        }
        {
          authenticated !== true
          && (
            <div className="ncss-container u-full-height">
              <div className="ncss-container fixed-fluid">
                <div className="ncss-row">
                  <div className="ncss-col-sm-12 mt6-sm mb8-sm ta-sm-c">
                    <h4 className="body-md-base mb4-xl mt4-xl">Please log in to use Nike Cam</h4>
                    <button
                      type="button"
                      className="ncss-btn-primary-dark"
                      onClick={this.login}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default withAuth(Home);
