import React, { Component } from 'react';

class AppControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      userinfo: null,
    };
    this.providersList = ['internal'];
    this.aggregatorsList = ['simpleAvg', 'coBrowseMatrix'];
  }

  render() {
    const { handleFileInput } = this.props;
    const { makeRequests } = this.props;
    return (
      <div className="ncss-row ncss-base">
        <div className="ncss-col-xxl-2 ncss-col-lg-2 ncss-col-sm-6 mb3-sm">
          <label
            className="ncss-btn-primary-dark pt2-sm pr5-sm pb2-sm pl5-sm"
            htmlFor="file-upload"
          >
            <i className="g72-training mr3-sm" />
            Choose File
          </label>
          <input type="file" id="file-upload" onChange={handleFileInput} hidden />
        </div>
        <div className="ncss-col-xxl-1 ncss-col-lg-2 ncss-col-sm-6 mb3-sm">
          <button
            type="button"
            className="ncss-btn-secondary-dark pt2-sm pr5-sm pb2-sm pl5-sm"
            onClick={makeRequests}
          >
            Send again
          </button>
        </div>
      </div>
    );
  }
}

export default AppControls;
