import React from 'react';
import Grid from '@material-ui/core/Grid';
import GridItem from '../GridItem/GridItem';

export default function GridContainer(props) {
  const { items } = props;
  const GridItemsContainer = items && items.length ? items.map(item => (
    <GridItem key={item.title + item.label} item={item} />
  )) : null;
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      {GridItemsContainer}
    </Grid>
  );
}
