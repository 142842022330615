import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, ImplicitCallback } from '@okta/okta-react';
import config from '../../config';
import Home from '../Home/Home';

export default function App() {
  return (
    <Router>
      <Security
        issuer={config.oidc.issuer}
        client_id={config.oidc.clientId}
        redirect_uri={config.oidc.redirectUri}
      >
        <Route path="/" exact component={Home} />
        <Route path="/okta-callback" component={ImplicitCallback} />
      </Security>
    </Router>
  );
}
