import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DocumentCard,
  DocumentCardPreview,
} from 'office-ui-fabric-react/lib-commonjs';
import { ImageFit } from 'office-ui-fabric-react/lib-commonjs/Image';

const styles = {
  baseStyle: {
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    padding: '0px',
  },
  activeStyle: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee',
  },
  rejectStyle: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee',
  },
  removePadding: {
    padding: '0',
  },
};

export default function PreviewCards(props) {
  const { file, handleFileInput } = props;
  const { base64 } = file;
  const { pdpUrl } = file;
  const { label } = file;
  const previewImage = {
    imageFit: ImageFit.contain,
    height: 184,
  };
  const submittedPreviewProps = {
    previewImages: [
      {
        ...previewImage,
        previewImageSrc: base64 ? `data:image/jpeg;base64, ${base64}` : null,
      },
    ],
  };
  const truthPreviewProps = {
    previewImages: [
      {
        ...previewImage,
        previewImageSrc: pdpUrl,
      },
    ],
  };
  // useCallback memoizes the results of the callback fn based on input.
  const onDrop = useCallback(handleFileInput, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop });
  let dynamicStyles = { ...styles.baseStyle };
  dynamicStyles = isDragActive ? { ...dynamicStyles, ...styles.activeStyle } : dynamicStyles;
  dynamicStyles = isDragReject ? { ...dynamicStyles, ...styles.rejectStyle } : dynamicStyles;

  return (
    <div className="ncss-col-xxl-2 ncss-col-lg-2 ncss-col-sm-12" style={{ verticalAlign: 'top' }}>
      <div className="ncss-col-xxl-12 ncss-col-lg-12 ncss-col-md-12 ncss-col-sm-12">
        <div
          {...getRootProps()}
          className="ncss-col-xxl-4 ncss-col-lg-4 ncss-col-sm-4"
          style={styles.removePadding}
        >
          <DocumentCard
            style={dynamicStyles}
          >
            <DocumentCardPreview {...submittedPreviewProps} />
          </DocumentCard>
          <input {...getInputProps()} />
        </div>
        <p className="ncss-base text-color-dark-grey">
          Submitted Image
        </p>
      </div>
      {
        pdpUrl
        && (
          <div className="ncss-col-xxl-12 ncss-col-lg-12 ncss-col-md-6 ncss-col-sm-12">
            <DocumentCard className="mb1-sm">
              <DocumentCardPreview {...truthPreviewProps} />
            </DocumentCard>
            <p className="ncss-base text-color-dark-grey">
              Truth Image
              <span className="ncss-base text-color-grey">
                {label ? ` - ${label}` : null}
              </span>
            </p>
          </div>
        )
      }
    </div>
  );
}
