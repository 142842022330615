// TODO: Pull in simple providersConfig from external source
const providersConfig = [
  {
    name: 'API',
    gender: 'all',
    annotators: [
      {
        name: 'google',
        minConfidence: 0.7,
      },
      {
        name: 'syte',
        minConfidence: 0.7,
      },
    ],
    providers: [
      {
        name: 'google',
        numResults: 50,
      },
      {
        name: 'syte',
        numResults: 50,
      },
    ],
    aggregation: 'simpleAvg',

  },
];

export default providersConfig;
