import React from 'react';
import { Spinner } from 'office-ui-fabric-react/lib-commonjs/Spinner';
import GridContainer from '../GridContainer/GridContainer';

const styles = {
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30vh',
  },
  listGridContainer: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
};

export default function ListGrid(props) {
  const { providers } = props;

  return (
    <div className="ncss-col-xxl-10 ncss-col-lg-10 ncss-col-sm-10 mt3-sm" style={styles.listGridContainer}>
      {
        providers.map(provider => (
          <div
            className="ncss-col-xxl-12 ncss-col-lg-12 ncss-col-sm-12 mb3-sm"
            key={`${provider.label} ${provider.title}`}
            style={{ verticalAlign: 'top' }}
          >
            {/* Provider Column Title. Show if results or error or spinner */}
            <div className="ncss-base text-color-dark-grey fs14-sm">
              {
                (provider.results || provider.error) || provider.showSpinner ? (
                  'Nike Cam Results'
                ) : (null)
              }
            </div>
            {/* Spinner */}
            {provider.showSpinner ? (
              <div style={styles.spinner}>
                <Spinner />
              </div>
            ) : (null)
            }
            {/* Results List */}
            {
              provider.results && provider.results.length ? (
                <GridContainer
                  items={provider.results}
                />
              ) : (null)
            }
            {/* Error */}
            {
              provider.error ? (
                <div className="ncss-base text-color-error fs14-sm">
                  {provider.error}
                </div>
              ) : (null)
            }
          </div>
        ))
      }
    </div>
  );
}
