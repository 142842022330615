import { NikeThemeProvider } from '@nike/nr-sole-xl-theme-provider';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

// changes during development may cause an issue with the service worker in
// your browser in which case you can import and call unregister to clear cached data
// import { unregister } from './registerServiceWorker';
// unregister();

ReactDOM.render(
  <NikeThemeProvider>
    <App />
  </NikeThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
