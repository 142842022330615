const stage = {
  dev: {
    oidc: {
      clientId: 'nike.ndi.nikecam-ops-client',
      issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
      redirectUri: 'http://localhost:3000/okta-callback',
      scope: 'openid profile email',
    },
    internalApi: {
      requestUrl: 'https://jl7flkkv4e.execute-api.us-west-2.amazonaws.com/dev/recommend',
    },
  },
  staging: {
    oidc: {
      clientId: 'nike.ndi.nikecam-ops-client',
      issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
      redirectUri: 'https://nikecam-ops.test.ndi.nikecloud.com/okta-callback',
      scope: 'openid profile email',
    },
    internalApi: {
      requestUrl: 'https://jl7flkkv4e.execute-api.us-west-2.amazonaws.com/dev/recommend',
    },
  },
  production: {
    oidc: {
      clientId: 'nike.ndi.nikecam-ops-client',
      issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
      redirectUri: 'https://nikecam-ops.ndi.nikecloud.com/okta-callback',
      scope: 'openid profile email',
    },
    internalApi: {
      requestUrl: 'https://nikevzn.ndi.svs.nike.com/recommendations/recommend',
    },
  },
};

const config = stage[process.env.REACT_APP_STAGE]
  ? stage[process.env.REACT_APP_STAGE]
  : stage.dev;

export default {
  ...config,
};
